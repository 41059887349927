import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
//import VideoPatientJourney from "../assets/EF_Video_PatientJourney_Portale_06_mitAbspann-resize-2.mp4"
import {
  mainContent,
  introSection,
  buttonGroup,
  description,
  row,
  col6,
  col12,
  marginTopSubstitute,
  borderBlue,
  //borderRed,
  borderPurple,
  borderGreen,
  borderOrange,
  borderSkyBlue,
  borderSunYellow,
  borderRosePink,
  borderSpringGreen,
  borderSoftOrange,
  desktopNavContainer,
  desktopNav,
  desktopContainer,
  sectionContent,
  content,
  hasSideNav,
  moduleNavButton,
  barTop,
  barMid,
  barBot,
  moduleNavOpen,
  moduleNavButtonOpen,
} from "./subpage.module.scss";
import Slider from "../components/slider";

const ModulePage = () => {
  const [mobileModuleNavIsVisible, setMobileModuleNavIsVisible] =
    React.useState(false);

  const handleMobileModuleNavClick = () => {
    setMobileModuleNavIsVisible(!mobileModuleNavIsVisible);
  };

  return (
    <Layout pageTitle="Module" neutralPage={true} smoothScroll={true}>
      <main className={mainContent}>
        
        
        <section className={`${introSection} ${hasSideNav}`}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <h1>Module</h1>
                <p className="text-medium">
                <span className="italic">health-engine®</span> Module, mit denen die Einzelverfahren der
                  Akteure zu einer Gesamtheit orchestriert werden.
                </p>
                <p className="text-medium">
                  Seit 2002 steigern wir die Qualität im Gesundheitswesen mit
                  digitalen Gesamtlösungen. Somit können bei der Digitalisierung
                  der Prozesse einfach innovative neue Lösungen eingebunden
                  werden, die bisher mit grossem Aufwand einzeln integriert
                  werden mussten. Die Umsetzung kann in grossen oder kleinen
                  Schritten passieren, so dass rasch ein Nutzen für die Anwender
                  entsteht.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <div className={desktopNavContainer}>
          <div
            className={`${desktopNav} ${
              mobileModuleNavIsVisible ? moduleNavOpen : ""
            }`}
          >
            <div>
              <h3>Medizinische Module</h3>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#labor-center"
              >
                Labor Center
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#dicom-center"
              >
                DICOM Center
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#medication-center"
              >
                Medication Center
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#stationstafel"
              >
                Stationstafel
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#video-center"
              >
                Video Center
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#order-entry"
              >
                Order Entry/Digitale Leistungsanforderung
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#mpi"
              >
                IHE MPI (Master Patient Index)
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#affinityDomain"
              >
                IHE Affinity Domain 
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#weisungen"
              >
                Weisungen
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#quali-management"
              >
                Qualitätsmanagement
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#blutspendedossier"
              >
                Blutspendedossier
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#berichtschreibung"
              >
                Arztbriefschreibung/Berichtschreibung
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#revisionstool"
              >
                Revisionstool
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#studien"
              >
                Studien, Forschung
              </Link>

              <h3>Digital Frontdoor and Single Point of Communication</h3>
              
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#portalwelt"
              >
                ti–e Portalwelt
              </Link>

              <Link to="#sprechstunde">SprechstundePlus</Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#telematikinfrastruktur"
              >
                Telematikinfrastruktur, ePA, KIM
              </Link>
             
              <h3 className={marginTopSubstitute}>Kommerzielle Module</h3>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#art"
              >
                ART (Anfrage und Rückweisungs Tool)
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#md-akte"
              >
                MD Akte (Medizinische Dienste)
              </Link>
            </div>
          </div>
          <div className={desktopContainer}>
            <section className={borderBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <p>
                      Gemeinsam mit unseren Lösungspartnern haben wir ein
                      Ökosystem verknüpfter Lösungen aufgebaut. Die Integration
                      ist dabei über die, von uns entwickelte, <span className="italic">health-engine®</span> als
                      zentrales Element gegeben.
                    </p>

                    <p>
                      Aus diesem stetig wachsenden Angebot standardisierter
                      Module setzen wir Ihre{" "}
                      <span className="text-blue">
                         individuelle Lösung zusammen
                      </span>
                      . Wir passen Ihre bestehende IT-Infrastruktur ohne grossen
                      Aufwand an sich ändernde Rahmenbedingungen an.
                    </p>

                    <p>
                      Unten sind die verschiedensten Module und Funktionalitäten
                      diese wir erstellt haben als Beispiel aufgelistet und die
                      herausgehobenen im Detail beschrieben.
                    </p>

                    <p>
                      Wir freuen uns, mit Ihnen in Kontakt zu treten und den
                      nutzenstiftenden Einsatz in ihrem Haus zu besprechen.
                    </p>
                  </div>
                </div>

                <div
                  id="content"
                  className={`${row} ${content}`}
                  style={{ paddingTop: 24 }}
                >
                  <div className={col6}>
                    <h3>Medizinische Module</h3>
                    <ul>
                      <li>
                        <Link to="#labor-center">Labor Center</Link>
                      </li>
                      <li>
                        <Link to="#dicom-center">DICOM Center</Link>
                      </li>
                      <li>
                        <Link to="#medication-center">Medication Center</Link>
                      </li>
                      <li> <Link to="#stationstafel">Stationstafel</Link></li>
                    
                      <li>
                        <Link to="#video-center">Video Center</Link>
                      </li>
                      <li>
                        <Link to="#order-entry">Order Entry/Digitale Leistungsanforderung</Link>
                      </li>
                      <li><Link to="#mpi">IHE MPI (Master Patient Index)</Link></li>

                      <li><Link to="#affinityDomain">IHE Affinity Domain</Link></li>

                      <li><Link to="#weisungen">Weisungen</Link></li>
                      <li><Link to="#quali-management">Qualitätsmanagement</Link></li>
                      <li><Link to="#blutspendedossier">Blutspendedossier</Link></li>
                      <li><Link to="#berichtschreibung">Arztbriefschreibung/Berichtschreibung</Link></li>
                      <li><Link to="#revisionstool">Revisionstool</Link></li>
                      <li><Link to="#studien">Studien, Forschung</Link></li>
                      <li>Mobile medizinische Apps</li>
                      <li>Befund Notifikation</li>
                      <li>Klinik Cockpit</li>
                      <li>Patienten Data Viewer</li>
                      <li>Clinical Data Viewer App</li>
                      <li>Meine Aufgaben</li>
                      <li>Weitere Apps</li>
                    </ul>
                  </div>
                  <div className={col6}>
                    <h3>Digital Frontdoor and Single Point of Communication</h3>
                    <ul>
                      <li>transfer engine</li>
                      <li>
                        <Link to="#portalwelt">ti–e Patientenportal</Link>
                      </li>
                      <li>
                        <Link to="#portalwelt">ti–e Zuweiserportal</Link>
                      </li>

                      <li>
                        <Link to="#portalwelt">ti–e Verlegungsportal</Link>
                      </li>
                      
                      <li> <Link to="#sprechstunde">SprechstundePlus</Link></li>
                      <li>
                        <Link to="#telematikinfrastruktur">
                          Telematikinfrastruktur, ePA, KIM
                        </Link>
                      </li>
                      <li>EPD Connector</li>
                      <li>Krebsregister</li> 
                      <li>Spitex-Anbindung</li>
                      <li>Versandmodul</li>
                    </ul>

                    <h3 className={marginTopSubstitute}>Kommerzielle Module</h3>
                    <ul>
                      <li>Kreditorenmanagement</li>
                      <li>
                        <Link to="#art">
                         ART (Anfrage und Rückweisungs Tool) 
                        </Link>
                      </li>
                      <li>
                        <Link to="#md-akte">
                          MD Akte (Medizinische Dienste)
                        </Link>
                      </li>
                      
                      <li>Haftpflichtmanagement</li>
                      <li>Bedarfsanforderung</li>
                      <li>Spesenmanagement</li>
                      <li>Vertragsmanagement</li>
                      
                    </ul>

                    <h3 className={marginTopSubstitute}>Querschnitt Module</h3>
                    <ul>
                      <li>Input-Management</li>
                      <li>Dokumentenmanagement</li>
                      <li>Personalmanagement</li>
                      <li>Projektmanagement</li>
                      <li>Formular Center</li>
                      <li>Machine Learning</li>
                      <li>E-Mail Archivierung</li>
                      <li>Scanning</li>
                      <li>PDF Services</li>
                      <li>PDF Services – print to <span className="italic">health-engine®</span></li>
                      <li>PFF Manipulator</li>
                      <li>Digital Document Processing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className={borderSpringGreen} id="labor-center">
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Labor Center</h1>
                    <p className="text-medium">
                      Labor Center konsolidiert alle Laborwerte fallbezogen und
                      patientenbezogen in einer gemeinsamen Darstellung.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <p>
                      Unterschiedliche Labors und Kliniken stellen eine Vielzahl
                      von nicht konsolidierten Daten und Werten zur Verfügung.
                      Labor Center konsolidiert sie fallbezogen und
                      patientenbezogen in einer gemeinsamen tabellarischen
                      Darstellung. Werte, die über oder unter der Norm liegen,
                      können mit individuellen Farben ausgezeichnet werden.
                    </p>

                    <p>
                      Der Vorteil vom Labor Center liegt nicht nur in der
                      gemeinsamen Darstellung von Daten und Werten aus
                      unterschiedlichen Quellen, sondern auch in der
                      zusätzlichen Möglichkeit, diese in Graphen abzubilden.
                      Damit werden Entwicklungen und Trend-Wenden auf einen
                      Blick sichtbar.
                    </p>

                    <p>
                      Diese neue Art der Verfügbarkeit erhöht Geschwindigkeit
                      und Präzision von Ableitungen der erhobenen Laborwerte.
                      Wie alle Module von <span className="italic">health-engine®</span> erfordert auch Labor
                      Center keine Neuprogrammierung und lässt sich als
                      zusätzlichen Bereich auf Patientenebene in kurzer Zeit
                      aufsetzen.
                    </p>
                  </div>

                  <div className={col12}>
                    <h3>Bedarf bei Spitäler</h3>
                    <ul>
                      <li>
                        Konsolidierte Sicht der Laborwerte von unterschiedlichen
                        Quellen
                      </li>
                      <li>Tabellarische Darstellung</li>
                      <li>Grafische Darstellung</li>
                      <li>Überblick über die Geschichte</li>
                      <li>Individualisierte Sichten</li>
                      <li>Warnungen bei Werten ausserhalb der Referenzwerte</li>
                    </ul>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <StaticImage
                      src="../assets/laborcenter01.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <div className={col12}>
                    <h3>Grafische Darstellung</h3>
                    <p>
                      Optional können die Werte grafisch dargestellt werden:
                    </p>
                    <ul>
                      <li>Darstellung einer Grafik der einzelnen Werte</li>
                      <li>über die Zeit Scrollen</li>
                      <li>über die Zeit Zoomen</li>
                      <li>
                        Grafisches anzeigen von einem „grünen Bereich“ welcher
                        vom Kunden definiert wird
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/laborcenter02.png"
                        alt="Beispielansicht Tabellarische Laborwertdarstellung - Notizen"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <section id="dicom-center" className={borderPurple}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>DICOM Center</h1>
                    <p className="text-medium">
                      HTML5 Viewer, VNA – Ablage, schneller Zugriff und
                      gesetzliche Archivierung für alle DICOM-Bilder.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <p>
                      Das DICOM Center schafft die Möglichkeit, sämtliche
                      Bilddaten in einem einzigen System zentral abzulegen. Via
                      das zentrale elektronische Patientendossier von
                      <span className="italic">health-engine®</span> ist der Zugriff auf die Bilddaten für alle
                      Mitarbeitenden mit entsprechender Berechtigung möglich.
                    </p>

                    <p>
                    <span className="italic">health-engine®</span> stellt alle nötigen Komponenten bereit, um
                      dieses Ziel zu erreichen. Der DICOMKnoten koppelt alle
                      Modalitäten und PACS-Systeme bidirektional, unabhängig vom
                      Hersteller. Das VNA (Vendor neutral Archive) sorgt für die
                      Ablage im Patienten- und Fallkontext, den schnellen
                      Zugriff und gewährleistet die gesetzliche Archivierung.
                      Über den schnellen HTML5-Viewer können alle Bilder, Serien
                      und Studien in voller Auflösung dargestellt werden.
                    </p>
                    <p>
                      Die Umsetzung des EPDG zwingt die Spitäler auch
                      DICOM-Bilder zur Verfügung zu stellen. Das DICOM Center
                      verfügt über alle Schnittstellen und Funktionalitäten um
                      dem EPDG gerecht zu werden.
                    </p>
                  </div>

                  <div className={col12}>
                    <h3>Facts</h3>
                    <ul>
                      <li>Ablage für alle Dicom Bilder</li>
                      <li>WADO-Service</li>
                      <li>
                        WADO Viewer für browser-basierte Anzeige, keine
                        Installation;
                      </li>
                      <li>Röntgenbilder, MRI, CT, ...</li>
                      <li>VNA (Vendor neutral archive)</li>
                      <li>
                        DICOM-Knoten mit Store, Retrieve, Storage Commitment;
                      </li>
                      <li>DICOM IHE IOCM</li>
                      <li>XDS-I.b-konform (EPDG)</li>
                      <li>EPD-Repository</li>
                      <li>EPD Image Document Consumer</li>
                    </ul>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/dicom-center01.png"
                        alt="Beispielansicht schnelle DICOM Preview"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                      <StaticImage
                        src="../assets/dicom-center02.png"
                        alt="Beispielansicht HTML-5 Viewer"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <section id="medication-center" className={borderSunYellow}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                  <p className={description}>in zusammenarbeit mit</p>
                    <div style={{ marginBottom: 16 }}>
                      <StaticImage
                        src="../assets/netcetera.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        formats={["auto", "webp", "avif"]}
                        width={180}
                      />
                    </div>
                    <h1>Medication Center</h1>
                    <p className="text-medium">
                      Patienten bezogenes Medikationsdossier – Die aktuelle
                      Medikation, sowie die Medikationshistorie werden vom
                      patientenbezogenen Medikationsdossier vollständig
                      abgebildet.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <p>
                      Eine Arzneimitteltherapie betrifft nicht nur den
                      Patienten, sondern alle involvierten Leistungserbringer.
                      Nur wenn alle Beteiligten den aktuellen Medikationsplan
                      des Patienten kennen, können richtige Entscheidungen im
                      Rahmen der Therapie getroffen werden. Eine
                      Grundvoraussetzung für ein Medikations- dossier ist ein
                      universales Datenmodell für Medikation und
                      Medikationshistorie.
                    </p>

                    <p>
                      Die Umsetzung des EDPG zwingt Spitäler und Pflegeheime
                      behandlungsrelevante Informationen, d.h. auch
                      Medikationsdaten, zur Verfügung zu stellen. Der Austausch
                      von Medikationsdaten zwischen den Leistungserbringern und
                      Institutionen erfolgt nach dem HL7-Standard und konform zu
                      den Vorgaben von eHealth Suisse. Aus dem
                      Medikationsdossier müssen sich die fünf Dokumente erzeugen
                      lassen: eMedicationTreatmentPlan, eRezept, eAbgabe,
                      eMedicationComment und eCurrentMedication.
                    </p>
                  </div>
                  <div className={col12}>
                    <h3>Bedarf bei Spitäler</h3>
                    <ul>
                      <li>
                        Automatische Übernahme der aktuellen Medikation des
                        Patienten bei Spitaleintritt sowie Bereitstellung der
                        Informationen zur Austrittsmedikation an die
                        nachgelagerten Leistungserbringer bzw.
                        Gesundheitsfachpersonen
                      </li>
                      <li>
                        Medikationsdossier zur Konsolidierung der
                        patientenbezogenen Medikamenteninformationen zwischen
                        den Systemen und Abteilungen bzw. Standorten
                      </li>
                      <li>
                        Steuerung der Kosten und Materialflüsse im Spital durch
                        Verfügbarkeit der patientenbezogenen Daten in Echtzeit
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section id="stationstafel" className={borderSkyBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                  <p className={description}>in zusammenarbeit mit</p>
                    <div style={{ marginBottom: 16 }}>
                      <StaticImage
                        src="../assets/netcetera.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        formats={["auto", "webp", "avif"]}
                        width={180}
                      />
                    </div>
                    <h1>Stationstafel</h1>
                    <p className="text-medium">
                    Ob auf der Intensivstation oder auf einer Bettenstation, der Überblick darüber, wer welche Patienten betreut erleichtert die Arbeit im Alltag.
                    </p>
                    </div>

                    <div className={col12}>
                    <p>Die Stationstafel bietet auf einen Blick allen Mitarbeitenden die nötigen Informationen. </p>
                    <h3>Dargestellt werden u.a.:</h3>
                    <ul>
                      <li>Statische, tabellarische Ansicht </li>
                      <li>Patienteninformationen, Betten, Pflege </li>
                      <li>Admin-Aufgaben (variabel pro Station) </li>
                      <li>Zuständigkeiten nach Berufsgruppe (variabel pro Station) </li>
                      <li>Visitenzeiten </li>
                      <li>Zuständiges Personal </li>
                    </ul>

                    <p>Die Stationstafel kann sowohl auf einem normalen Desktop wie auch auf einen Grossbildschirm präsentiert werden.</p>
                  </div>
                </div>

             
              </div>
            </section>

            {/* <section id="cirs" className={borderSkyBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                  
                    <h1>CIRS (Critical incident reporting system) </h1>
                    <p className="text-medium">
                    Die <span className="italic">health-engine®</span> bietet eine Lösung zur anonymen Erfassung von CIRS-Fällen mit den gewünschten Metadaten. Der HTML5 Webaufruf 
                    kann in das Intranet integriert werden und die Daten werden lokal auf dem eigenen Server gespeichert. 
                    </p>
                    </div>

                    <div className={col12}>
                    <p>Die Benutzeroberfläche ist geräteunabhängig und kann in jedem modernen Browser verwendet werden. Die Lösung ermöglicht eine 
                      Übersicht über Lehrfälle, eine einfache Administration von Wertelisten und CIRS-Fällen sowie eine intuitive Eingabe von kritischen Vorfällen. 
                      CIRS-Manager haben die Möglichkeit zur Analyse der Fälle.  </p>
                    
                    <p>Es besteht auch die Möglichkeit, die Lösung an das CIRRNET anzubinden. Das System ist vollständig in alle Prozesse der <span className="italic">health-engine®</span> integriert.</p>
                  </div>
                </div>

             
              </div>
            </section> */}

            <section id="video-center" className={borderOrange}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Video Center</h1>
                    <p className="text-medium">
                      Webbasierter Zugriff auf Videos – Unterschiedlichstes
                      Videomaterial wird vollautomatisch über HTML5 User
                      Interfaces auf PC und mobilen Geräten schnell und
                      unkompliziert bereitgestellt.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <p>
                      Die Anzahl von Videoaufzeichnungen steigt rasant (z.B.
                      OP-Aufzeichnungen, Endoskopien, Sonographien). Dies führt
                      zu einer starken Zunahme von unterschiedlichstem Material.
                      Die Videos müssen mit viel Aufwand in ein einheit- liches
                      und allgemein lesbares Format transkodiert werden.
                      Aufgrund des in aller Regel grossen Zeitdrucks sollte
                      diese Umwandlung effizient und reibungslos
                      vonstattengehen. Exakt hier setzt das Video Center an.
                    </p>

                    <p>
                      Über diverse Schnittstellen werden die Videos der
                      unterschiedlichsten Aufzeichnungsgeräte importiert. {/* Der
                      CODECCHEF transkodiert alle diese Videos sofort in das
                      Standardformat H.264. Darauf lassen sie sich auf allen
                      HTML5 User Interfaces von <span className="italic">health-engine®</span> anzeigen.*/}
                    </p>
                  </div>
                  <div className={col12}>
                    <h3>Facts</h3>
                    <ul>
                      <li>Ablage für alle Arten von Videos;</li>
                      <li>Automatische Konvertierung auf H.264;</li>
                      <li>Schnelles Video Streaming, kein Download;</li>
                      <li>
                        Streaming mit schmalen Bandbreiten und maximaler
                        Auflösung;
                      </li>
                      <li>Viewing Komponente für jeden Browser;</li>
                      <li>
                        Viewer ist als Komponente in Applikationen und Websites
                        integrierbar;
                      </li>
                      <li>Berechtigungssteuerung auf jedes einzelne Video.</li>
                    </ul>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Video Streaming</h3>
                    <p>
                      Das <span className="italic">health-engine®</span> Streaming ist ein
                      Datenübertragungsverfahren, bei dem die Daten bereits
                      während der Übertragung angesehen oder angehört werden
                      können und nicht erst nach der vollständigen Übertragung
                      der Daten. Es gibt damit keine Wartezeiten bei der Anzeige
                      und es kann schnell vorwärts und rückwärts gespult werden.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/videocenter01.png"
                        alt="Beispielansicht schnelles Video Streaming"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <section id="order-entry" className={borderRosePink}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                
                   
                    <h1>Order Entry/Digitale Leistungs&shy;anforderung</h1>
                    <p className="text-medium">
                    Order Entry/Digitale Leistungsanforderung ist eine wichtige Basis für Effizienz und Wirtschaftlichkeit im Krankenhaus.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Das Problem:</h3>
                    <p>
                    Da in den Abteilungen spezielle Systeme für die Auftragsplanung und Durchführung eingesetzt werden, 
                    fehlt in der Regel ein Überblick über alle Aufträge zu einem Patienten. Dies erschwert die Abrechnung und das Verhindern 
                    doppelter Aufträge. Zudem müssen die Aufträge häufig in diesen Primärsystemen direkt erfasst werden, was hohen Ausbildungsaufwand 
                    edeutet.
                    </p>
                      
                    <h3 className={marginTopSubstitute}>Die Lösung:</h3>
                    <p>
                    Das Modul <span className="italic">Order Entry</span> der <span className="italic">health-engine®</span> bietet eine zentrale und umfassende Lösung für alle Arten von Aufträgen. 
                    Mit dem Modul Order Entry schließt <span className="italic">health-engine®</span> eine Optimierungslücke im stationsübergreifenden Workflow. Bisherige im KIS 
                    eingebaute Komponenten, werden im Order Entry neu strukturiert und für verschiedene Anwendergruppen in den entsprechenden Systemen 
                    verfügbar gemacht.
                    </p>

                    <h3 className={marginTopSubstitute}>Der Nutzen: </h3>
                    <p>
                    Order Entry / die Digitale Leistungsanforderung wird vereinfacht, umfassend und transparenter. Dadurch werden Mitarbeiter 
                    entlastet und Arbeitsabläufe beschleunigt. Ferner wird das Verhindern von Doppelbuchungen erleichtert, die korrekte Leistungsabrechnung 
                    nterstützt und der Durchblick beim Controlling erhöht.
                    </p>

                    <p className="italic-blue">
                    Für Deutschland: Order Entry erfüllt die Anforderungen des Fördertatbestands 6 laut KHZG
                    </p>

                    <p>
                    <span className="italic">health-engine®</span> bietet eine zentrale Lösung für alle Arten
                      von Aufträgen.
                    </p>
                  </div>

                  <div className={col12}>
                    <h3>Auftragsarten</h3>
                    <ul>
                      <li>Radiologie</li>
                      <li> Konsil</li>
                      <li>Diagnostik </li>
                      <li>Labor</li>
                      <li>Patiententransport </li>
                      <li>Blutprodukte</li>
                      <li>Therapien intern</li>
                      <li>Tumorkonferenzen </li>
                      <li>Pflegekonsil</li>
                      <li>Gipszimmer</li>
                      <li>Sprechstundenauftrag</li>
                      <li>OP-Anmeldung und weitere...</li>
                    </ul>
                  </div>
                
                    <h3 >
                      Überblick über die Aufträge
                    </h3>
                    <p>
                      <span className="italic">health-engine®</span> bietet eigene Auftragsprozesse pro
                      Auftragsart mit der Möglichkeit von Unterauftragsarten. Ob
                      ein Auftrag über eine Schnittstelle, über eine URL oder
                      einfach nur per E-Mail oder Ausdruck erfasst wird,
                      entscheiden die Kunden.{" "}
                    </p>

                    <p>
                      Updates zu Aufträgen können bidirektional von
                      <span className="italic">health-engine®</span> oder vom Auftragssystem erfasst werden.
                      Aufträge können je nach System über die Oberfläche einfach
                      storniert werden. Befunde, Labordaten, DICOM-Objekte und
                      weitere auftragsbezogene Daten, Befehle und Funktionen
                      können von <span className="italic">health-engine®</span> empfangen, mit dem Auftrag
                      verlinkt und auf der Oberfläche übersichtlich dargestellt
                      werden.
                    </p>
                  
                    <h3 className={marginTopSubstitute}>
                      Auftragserfassung 
                    </h3>
                    <p>
                      Aufträge werden über einheitliche webbasierte Masken
                      erfasst, die auch in Fremdapplikationen eingeblendet
                      werden können. <span className="italic">health-engine®</span> behält dabei den Überblick
                      über die Aufträge, den Status dieser Aufträge und die
                      zugehörigen&shy;strukturierten und unstrukturieten Resultate,
                      wie Werte, Berichte, Bilder, usw{" "}
                    </p>

                    <h3 className={marginTopSubstitute}>
                      Kommunikation 
                    </h3>
                    <p>
                      Die Kommunikation mit den Primärsystemen geschieht über
                      standardisierte oder auch proprietäre Schnittstellen.{" "}
                    </p>

                    <h3 className={marginTopSubstitute}>Einbettung in Dritt-Applikationen</h3>
                    <p>Das Order Entry Modul der <span className="italic">health-engine®</span> ist als HTML5-Komponente aufgebaut. HTML5-Komponenten können in Dritt-Applikationen (z.B. KIS, KAS, etc. eingebunden werden)</p>
                    
                  </div>

                
                <div><p></p></div>

                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/order-entry.png"
                        alt="Order Entry einbettung in Dritt-Applikationen"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                     
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <section id="mpi" className={borderPurple}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>IHE MPI (Master Patient Index)</h1>
                    <p className="text-medium">
                    Der Master Patient Index (MPI) ist ein Anwendungssystem zur Verwaltung von Patientenidentitäten. 
                    </p>
                   
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>
                    MPI Beschreibung
                    </h3>
                    <p>Dabei hält der MPI Einrichtungs- und/oder Anwendungssystem-interne Patientenidentifikationsdaten verschiedener (dezentraler) 
                      Systeme in einer Datenbank unter einer eindeutigen Kennung (globale ID) vor. Er ermöglicht die Verknüpfung von Patientendaten und Duplikaten, die in den verschiedenen 
                      Anwendungssystemen erfasst werden, sodass eine eindeutige, systemübergreifende Identifizierung von Patienten ermöglicht wird.</p>
                    <p>Für die Anbindung von weiteren patientenführenden Systemen etabliert sich ein MPI als zentrale Komponente zur Verwaltung der Patientenidentitäten, um über Systeme und 
                        Einrichtungen hinweg ein zentrales Patientenmanagement abbilden zu können</p>
                  </div>

                  <div className={col12}>
                  <h3>MPI Funktion</h3>
                    <p>Der MPI bewertet die Ähnlichkeit der Patienten-Stammdaten in den unterschiedlichen Systemen. Über Definition von 
                        Schwellwerten kann geregelt werden, ab welchem Schwellwert eine Verknüpfung automatisiert erfolgt (oberhalb des oberen 
                        Schwellwertes) oder innerhalb welcher Spanne (unterer und oberer Schwellwert) es sich um einen potenziellen Verknüpfungskandidaten handelt. Dieser Fall 
                        benötigt ein Manuelles Clearing einer hausinternen Clearingorganisation, die eng mit der Patientenadministration der beteiligten 
                        Einrichtungen zusammenarbeitet.</p>
                    <p>Die Oberfläche ist flexibel gestaltet, sodass beliebige Informationen angezeigt werden können, wie beispielsweise Dubletten 
                        innerhalb der Stammdaten-Domain oder Berechtigungen.</p>
                    <p>Die integrierte Workflow Engine ermöglicht Kunden, individuelle Prozesse in Abhängigkeit der Verknüpfungs-Ergebnisse zu erstellen, die dann automatisiert ablaufen.</p>
                    <p>Der MPI stellt für die Anbindung von Systemen unterschiedliche Schnittstellen bereit und unterstützt sowohl proprietäre Datenformate als auch standardisierte Formate 
                        (HL7, IHE).</p>
                    <p>Der Funktionsumfang umfasst die präventive Überwachung aller Schnittstellen, samt Möglichkeit eines Email-Alerts bei Ausfall 
                        oder Nicht -Erreichbarkeit. Überwachungszeitraum und Prüfintervall sind dabei vom Kunden definierbar.</p>
                    </div>

                  <div className={col12}>
                    <h3>Funktionale Highlights</h3>
                    <ul>
                      <li>Anschluss beliebig vieler Mandanten/Stammdaten-Domains</li>
                      <li>Führen des Master-PID und der PIDs pro angeschlossenes System</li>
                      <li>Workflow Engine zur Steuerung von Kundenprozessen</li>
                      <li>Algorithmus spezifisch anpassbar. Im Standard sind Schwellwerte für 3 Fälle definierbar:</li>
                          <ul>
                          <li>Automatische Verknüpfung</li>
                          <li>Manuelles Clearing</li>
                          <li>Kein Match = neuer Masterpatient</li>
                          </ul>
                        <li>Möglichkeit zum Anzeigen beliebiger Informationen, wie z.B. Dubletten</li>
                        <li>High-end Berechtigungssteuerung</li>
                        <li>Vollständige Protokollierung</li>
                        <li>Standardisierte Schnittstellen nach IHE:</li>
                          <ul>
                            <li>Patient feed ITI-8, ITI-44, ITI-93</li>
                            <li>PIX query ITI-9, ITI-45, ITI-83</li>
                            <li>PIX feed ITI-10, ITI-46, ITI-104</li>
                            <li>Proprietäre Schnittstellen realisierbar</li>
                          </ul>
                    </ul>
                    <p>Aufgrund der Leistungsfähigkeit der Lösung, der einfachen Bedienung und dem hohen 
                      Automatisierungsgrad, eignet sich der MPI sowohl für Klinikgruppen, als auch für regionale oder landesweite Vernetzungen</p>

                    
                  </div>
                </div>
                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/MPI.png"
                        alt="MPI Architektur"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                        
                    </Slider>
                  </div>
                </div>
            
              </div>
            </section>

            <section id="affinityDomain" className={borderSkyBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>IHE Affinity Domain</h1>
                    <p className="text-medium">
                    Für einen erleichterten Datenaustausch im Gesundheitswesen unterstützen wir Sie durch die <span className="italic">health-engine® </span>
                    mit folgenden Komponenten: XDS für den Austausch von medizinischen Dokumenten, XDS-i für den Austausch von Bilddaten, 
                    MPI für die Verwaltung eindeutiger Patientenidentifikatoren und HPD für den Austausch von Informationen über medizinische Dienstleister. </p>
                    </div>

                    <div className={col12}>
                    <p>Diese Komponenten arbeiten zusammen, um einen sicheren und effizienten Austausch von medizinischen Daten zu ermöglichen und die 
                    Zusammenarbeit im Gesundheitswesen zu verbessern.</p>
                    
                  </div>
                </div>

             
              </div>
            </section>

            <section id="weisungen" className={borderSpringGreen}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Weisungen</h1>
                    <p className="text-medium">
                    Dieses Modul umfasst das ganze Regelwerk, mit welchem eine Organisation gesteuert und gelenkt wird. Einschließlich interner 
                    und externer Dokumente wie Prozessbeschreibungen, Arbeitsanweisungen, Dienstreglements, Checklisten und Dokumentvorlagen.
                    
                    </p>
                    </div>

                    <div className={col12}>
                    <p>Es unterstützt den Lebenszyklus der Weisungen von der Antragstellung bis zur Freigabe und Archivierung. Die Benutzer werden 
                      bei der Erstellung und Überprüfung unterstützt, und die Suche nach Weisungen wird durch intelligente Beschreibung und 
                      Verschlagwortung erleichtert. </p>

                      <p>Die Prozesssicherheit wird erhöht, und mehrdimensionale Berechtigungen stellen sicher, dass 
                      Benutzer nur die für sie vorgesehenen Informationen sehen und bearbeiten können. Das Modul bietet auch Office-Integration, 
                      Publikationsmöglichkeiten, Terminüberwachung und Volltextsuche.</p>

                  </div>

                  <div className={col12}>
                  <h3>Moduleigenschaften</h3>
                      <ul>
                      <li>Regelwerk (intern und extern)</li>
                      <li>Prozessbeschreibungen</li>
                      <li>Arbeitsanweisungen</li>
                      <li>Dienstreglements</li>
                      <li>Checklisten</li>
                      <li>Dokumentvorlagen</li>
                      <li>Mehrdimensionale Berechtigungen</li>
                      <li>Office-Integration</li>
                      <li>Prozessunterstützung</li>
                      <li>Publikation</li>
                      <li>Terminüberwachung</li>
                      <li>Volltextsuche</li>
                      </ul>

                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/Weisungen.jpeg"
                        alt="Beispielprozess Weisungen"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Slider>
                  </div>
                </div>
             
              </div>
            </section>

            <section id="quali-management" className={borderOrange}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Qualitätsmanagement</h1>
                    <p className="text-medium">
                    Dieses Modul bietet eine Vielzahl von Funktionen für die Verwaltung und Steuerung verschiedener 
                    Dokumenttypen wie Weisungen, Anleitungen, Formulare, Dokumentvorlagen, Prozessbeschreibungen, Checklisten und Protokolle. 
                    </p>
                    </div>

                    <div className={col12}>
                    <p>Es ermöglicht die Erstellung frei strukturierter Ordner, die rechtskonforme Archivierung, die Office-Integration zur 
                      automatischen Erfassung von Attributen in Word-Dokumenten und Excel-Tabellen, sowie die Konvertierung in das PDF-Format.</p>

                      <p>Es bietet eine leistungsstarke Suche und Volltextsuche, ermöglicht externe Zugriffe über REST-Aufrufe oder 
                        einfache URLs und unterstützt den gesamten Prozess vom Import über die Prüfung und Freigabe bis zum Zugriff über das Intranet. 
                        Benachrichtigungen per E-Mail und SMS, Aufgabenlisten, Protokollierung und Journal-Funktionalität sind ebenfalls enthalten. </p>

                    <p>Die 
                        Benutzer können Dokumente aus Vorlagen erstellen oder per Drag & Drop importieren, und es können beliebig viele Prüfungen
                        (fachlich und formal) definiert werden. Rollen wie Bearbeiter, fachlicher Prüfer, formaler Prüfer und Freigeber werden unterstützt.</p>
                    
                  </div>
                </div>

             
              </div>
            </section>

            <section id="blutspendedossier" className={borderBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Blutspendedossier</h1>
                    <p className="text-medium">
                    Die folgenden Vorteile tragen zu einer effektiveren und sicheren Blutspendepraxis bei, indem sie den Prozess optimieren, die Datenintegrität gewährleisten 
                    und die Einhaltung gesetzlicher Vorschriften unterstützen: 
                    </p>
                    </div>

                    <div className={col12}>
                    <ul>
                      <li><span className="italic">Effizienzsteigerung:</span> Durch die Übernahme von Daten aus dem führenden Admin-System und das Scannen und Ablage von Dokumenten wird der Prozess der Blutspende, 
                        Lieferung und Behandlung optimiert. Dadurch können Zeit und Ressourcen eingespart werden.</li>
                      <li><span className="italic">Datenintegrität:</span> Durch die genaue Übernahme von Spender- und Patientendaten aus dem Admin-System wird die Datenintegrität gewährleistet. Dadurch werden Fehler 
                      minimiert und die Genauigkeit der Informationen verbessert.</li>
                      <li><span className="italic">Gesetzeskonformität:</span> Die gesetzeskonforme Archivierung aller relevanten Dokumente erfüllt die rechtlichen Anforderungen im Umgang mit Spender- und Patientendaten. 
                      Dies hilft, mögliche rechtliche Konsequenzen zu vermeiden.</li>
                      <li><span className="italic">Nachverfolgbarkeit:</span> Durch die Abbildung von Chargen und die Verknüpfung mit den Produktionen wird eine detaillierte Nachverfolgbarkeit des Blutprodukts ermöglicht. 
                      Im Falle von Qualitätsproblemen oder Rückrufen kann schnell und präzise reagiert werden.</li>
                      <li><span className="italic">Verbesserte Patientenversorgung:</span> Die Übernahme von Patienten- und Falldaten ermöglicht einen umfassenden Einblick in den Gesundheitszustand des Patienten. Dies unterstützt 
                      die medizinische Versorgung und ermöglicht eine bessere Behandlungsplanung.</li>
                      <li><span className="italic">Reduzierung von Papierkram:</span> Durch das Scannen und Archivieren von Dokumenten wird der Bedarf an physischen Papierdokumenten reduziert. Dies führt zu einer papierlosen und 
                      umweltfreundlicheren Arbeitsumgebung.</li>
                      <li><span className="italic">Einfache Datenzugänglichkeit:</span> Durch die elektronische Archivierung der Daten wird der Zugriff auf Spender-, Liefer- und Behandlungsinformationen erleichtert. Die Informationen 
                      können schnell abgerufen werden, was die Effizienz und den Informationsfluss verbessert.</li>
                      <li><span className="italic">Verbessertes Qualitätsmanagement:</span> Durch die Archivierung von Protokollen aus der Produktion und Berichten im Zusammenhang mit der Behandlung können Qualitätskontrollen und Audits 
                      effektiv durchgeführt werden. Dies trägt zur Sicherstellung der Produktqualität und Patientensicherheit bei.</li>


                    </ul>
                    
                  </div>
                </div>
              </div>
            </section>

            <section id="berichtschreibung" className={borderSoftOrange}>
              <div className={sectionContent}>
                <div className={row}>
                <div className={col12}>
                  <p className={description}>in zusammenarbeit mit</p>
                    <div style={{ marginBottom: 16 }}>
                      <StaticImage
                        src="../assets/netcetera.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        formats={["auto", "webp", "avif"]}
                        width={180}
                      />
                    </div>
                    </div>

                    <div className={col12}>
                    <h1>Arztbriefschreibung/&shy;Berichtschreibung</h1>
                    <p className="text-medium">
                    Das Modul Arztbriefschreibung/&shy;Bericht&shy;schreibung unterstützt die Erstellung von Arztbriefen auf Basis der vorhandenen Informationen. 
                    </p>
                    </div>

                    <div className={col12}>
                    <p>Die bereits in anderen Systemen vorhanden Daten zum Patienten werden in <span className="italic">health-engine®</span> konsolidiert und im Berichtschreibungsmodul für die einfache Bearbeitung zur Verfügung gestellt.</p>
                    
                    <h3>Ihr Nutzen</h3>
                    <ul>
                      <li>Einfache und standardisierte Erstellung der Arztbriefe auf Basis bestehender Informationen </li>
                      <li>In Ergänzung mit dem Versandmodul einfacher Versand an die verschiedenen Empfänger </li>
                      <li>Nachbearbeitung im Bearbeitungs-Modus, wo nötig, einfach möglich</li>
                    </ul>
                    </div>
                    
                </div>
              </div>
            </section>



            <section id="revisionstool" className={borderGreen}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Revisionstool</h1>
                    <p className="text-medium">
                    Das Revisionstool von <span className="italic">health-engine®</span> erleichtert den Prozess der stationären Abrechnungsprüfung in Krankenhäusern. Jedes Jahr werden Krankenhäuser von einer Revisionsfirma überprüft, 
                    wobei ein Revisor stichprobenartig Fälle aus dem vergangenen Jahr prüft.  </p>
                   </div>
                   
                   <div className={col12}>
                    <p>Der Schwerpunkt liegt dabei auf der Kodierung und der daraus resultierenden Abrechnung. Der erstellte Revisionsbericht hat einen großen Einfluss auf die Liquidität des Hauses und die Rechnungsbeanstandungen der Kostenträger. </p>
                    <p>Das Revisionstool ermöglicht es, die für die Revision relevanten Informationen aus den umfangreichen elektronischen Dokumenten in <span className="italic">health-engine®</span> gezielt zugänglich zu machen, während der Datenschutz gewährleistet bleibt. </p>
                    <p>Durch die elektronische Abbildung des gesamten Revisionsprozesses sparen die Sachbearbeiter Zeit und können sich auf die Aufbereitung der Fälle konzentrieren, um optimale Prüfungsergebnisse zu erzielen.</p>
                  </div>
                </div>
              </div>
            </section>

            <section id="studien" className={borderSkyBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                  
                    <h1>Studien, Forschung </h1>
                    <p className="text-medium">
                    Das Clinical Trial Repository führt Daten aus verschiedenen Quellen zu einem kontinuierlichen Real-World-Evidence-Datenstrom zusammen. Es importiert Daten in verschiedenen Formaten wie HL7, XML und CSV und speichert diese im generischen Datenmodell der <span className="italic">health-engine®</span>. 
                    </p>
                    </div>

                    <div className={col12}>
                    <p>Das Modell erlaubt den Import und die Speicherung von Patienten- und Falldaten, Diagnosen, Behandlungen, Laborergebnissen, Vitaldaten, Therapien, Leistungen sowie Medikamenten und Materialien. </p>
                    
                    <p>Die Daten werden pseudonymisiert und neue Daten können einfach hinzugefügt werden. Für klinische Auswertungen können die Daten anonymisiert und im ODM XML (CDISC) Format in ein Data Warehouse exportiert werden.</p>
                  </div>
                </div>

             
              </div>
            </section> 



            <section id="portalwelt" className={borderSunYellow}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ti–e Portalwelt</h1>
                    </div>
                </div>

                <div className={row}>
                  <div className={col12}>

                    <h3 >
                    Die Herausforderung
                    </h3>
                   <p>Die moderne Gesundheitsversorgung steht vor dem Dilemma, den wachsenden Bedarf an personalisierter und effizienter Patientenbetreuung 
                    zu erfüllen, während sie gleichzeitig mit beschränkten Ressourcen haushalten. Die Notwendigkeit einer Lösung, die administrative Prozesse strafft, 
                    die Interaktion zwischen medizinischem Personal und Patienten verbessert und eine nahtlose Verlegung zwischen verschiedenen Einrichtungen ermöglicht, 
                    ist offensichtlich. </p>
                    </div>
                    <div className={col12}>

                    <h3>Die Lösung: Das integrierte Portal-System</h3>
                    <p>Wir haben ein umfassendes Portal-System entwickelt, das auf drei Haupt-Säulen ruht: dem ti–e Zuweiserportal, dem ti–e Patientenportal und dem ti–e Verlegungsportal. 
                      Dieses Trio arbeitet Hand in Hand, um eine nahtlose, effiziente und patientenzentrierte medizinische Betreuung zu gewährleisten. </p>
                    
                    <ul>
                      <li><span className="text-blue">Das ti–e Zuweiserportal:</span> Hier beginnt die Reise eines Patienten. Ärzte können schnell und unkompliziert 
                      Überweisungen durchführen, alle notwendigen Unterlagen übermitteln und den Patienten direkt in das System des Klinikums einbinden. 
                      Ein Beispiel ist Kathrin Huber, deren Überweisung durch Dr. Fischer schnell und sicher über das ti–e Zuweiserportal abgewickelt wurde. 
                      <span style={{ marginTop: 10, marginBottom: 20}} className={buttonGroup}><a href="https://www.tie.ch/portale#z-p"className="button-outline orange" target="_blank" rel="noreferrer"> Weitere Infos zum ti–e Zuweiserportal </a> </span></li>
                      
                      <li><span className="text-blue">Das ti–e Patientenportal:</span> Nach der Überweisung tritt der Patient in eine interaktive Phase ein. 
                      Kathrin konnte sich bequem von zu Hause aus in das System einloggen, Termine vereinbaren, notwendige Unterlagen einsehen und 
                      vorbereitende Schritte vor ihrem Krankenhausaufenthalt treffen. Dieses ti–e Portal stellt nicht nur eine Brücke zur medizinischen 
                      Versorgung dar, sondern ermächtigt auch den Patienten, aktiv an seiner Behandlung teilzunehmen. 
                      <span style={{ marginTop: 10, marginBottom: 20}} className={buttonGroup}><a href="https://www.tie.ch/portale#p-p"className="button-outline orange" target="_blank" rel="noreferrer"> Weitere Infos zum ti–e Patientenportal </a> </span></li>

                      <li><span className="text-blue">Das ti–e Verlegungsportal:</span> Nach der stationären Behandlung unterstützt das Portal die nahtlose 
                      Überführung des Patienten in nachgelagerte Einrichtungen, wie Reha-Zentren. Kathrins reibungslose Verlegung in eine Reha-Einrichtung 
                      wurde durch das integrierte Verlegungsportal ermöglicht, welches eine effiziente, transparente und koordinierte Weiterbetreuung sicherstellt. 
                      <span style={{ marginTop: 10, marginBottom: 20}} className={buttonGroup}><a href="https://www.tie.ch/portale#v-p"className="button-outline orange" target="_blank" rel="noreferrer"> Weitere Infos zum ti–e Verlegungsportal </a> </span></li>
                    </ul>
                      </div>

                      <div className={col12}>
                    <h3>Die Stärken</h3>
                    <p>Das System baut auf der Stärke der medizinischen Interoperabilitätsplattform auf. Es ermöglicht die Integration verschiedener 
                      Primärdokumentationssysteme und bietet so eine flexible, datengesteuerte Plattform, die essenziell für die Unterstützung 
                      komplexer Prozesse wie administrative Aufnahmen und Entlassmanagement ist. </p>

                      
                    </div>

                    <div className={col12}>
                  
                    <h3>Videos zur ti–e Portalwelt</h3>
                    <p>Nachfolgend zwei animierte Videos, die das Zusammenspiel von ti–e Zuweiserportal, ti–e Klinikportal und ti–e Patientenportal in der Patient-Journey von Kathrin Huber zeigen. </p>
                    
                          <p>Aus Sicht des Patienten:</p>
                    <a 
                  
                  href="https://www.tie.ch/landingpages/portal-journey"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../assets/p-p-PatientJourney-Cover.jpg"
                    alt="Interview mit Andreas Rygol"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </a>

                <p className={marginTopSubstitute}>Aus Sicht des Spitals:</p>

                <a 
                  href="https://www.tie.ch/landingpages/portal-journey"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../assets/EFSpitalJourneyVideo.png"
                    alt="Interview mit Andreas Rygol"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </a>
                    </div>
                   
                  
                </div>

               
              </div>
            </section>

{/*
            <section id="zuweiserportal" className={borderRed}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Zuweiser&shy;portal</h1>
                    <p className="text-medium">
                      Mit uns begleiten Sie Ihre Zuweiser Digital – Das Modul
                      <span className="italic"> Zuweiserportal</span> unterstützt die Kommunikation mit den
                      niedergelassenen Ärzten über einen gesicherten Kanal und ermöglicht die Digitalisierung von vormals manuellen Prozessen.
                    </p>
                    <p className="text-medium">
                    Mit der Lösung bieten Sie Ihren wichtigsten Geschäftspartnern einen digitalen Service via App oder über den Browser. 
                    Die Integration in die hausinternen Anwendungen erfolgt über <span className="italic">health-engine®.</span>
                    </p>

                    </div>

                    <div className={col12}>
                    <p>Das Modul <span className="italic"> Zuweiserportal</span> wird wahlweise als SaaS aus der Cloud oder als On-Premise Lösung angeboten.</p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>
                      Das Zuweiserportal bietet folgende Ansichten und Hauptfunktionalitäten
                    </h3>
                    <ul>
                      <li>Design im CI der Klinik</li>
                      <li>
                        Anzeige der eigenen Patienten und deren Fälle in der Klinik
                      </li>
                      <li>
                        Anzeige der für den Patienten im Spital vereinbarten Termine
                      </li>
                      <li>
                      Möglichkeit, einen neuen Termin im Zusammenhang mit einer Überweisung anzufragen
                      (für extern publizierte Terminmöglichkeiten aus dem terminführenden System) 
                      </li>
                      <li>
                      Anzeige von Dokumenten des Patienten (Freigabe erfolgt durch die Klinik im entsprechenden internen System)
                      </li>
                      <li>
                      Anzeige von Radiologie-Bilder innerhalb eines eingebundenen Viewers (Xero-Viewer)
                      </li>
                      <li>
                      Erteilung von Aufträgen an die Klinik (z.B. radiologische Untersuchungen) 
                      </li>
                      <li>
                      Möglichkeit der Einbindung von HTML5-fähigen Anwendungen
                      </li>
                      <li>
                      Management des Profils durch den Benutzer, inklusive Hinterlegung von administrativen Informationen
                      </li>
                      <li>
                      Möglichkeit, weitere Informationen der Klinik einzubinden (z.B. Service-Angebote, Informationen zu Behandlungen etc.)
                      </li>
                      <li>
                      Möglichkeit der Verwaltung von Abonnements (welche Informationen sollen wie zugestellt werden)
                      </li>
                      <li>Wo bereits (Teil-)Lösungen vorhanden sind, können diese integriert werden.</li>
                    </ul>
                  </div>

                  <div className={col12}>
                  <h3>
                      Kommunikation über einen gesicherten Kanal
                    </h3>
                    <p>
                    Informationen werden dem Zuweiser über den digitalen Kanal unkompliziert und abgesichert zugestellt. Die Daten der Patienten werden verschlüsselt übermittelt. 
                    </p>

                    <p>
                    Für das Entlassmanagement in Kombination mit der TI-Anbindung (für DE) und dem Versandmodul ermöglicht das Einweiserportal 
                    den sicheren und prozessgesteuerten Versand von Benachrichtigungen, Entlassungsbriefen und Dokumenten.
                    </p>
                    <p>
                    Der Einsatz von moderner Front-End Technologie ermöglicht die Integration von eigenen Anwendungen genauso wie eine einfache Präsentation auf verschiedensten Ausgabegeräten.
                    </p>
                    </div>

                  <div className={col12}>
                    <h3>Ihr Nutzen</h3>
                    <ul>
                      <li>
                        Digitaler Zugang für Ihre Zuweiser zu den Belangen zugewiesener Patienten</li>
                      <li>Direkte Integration in die internen Anwendungen und damit Reduktion von manuellen Arbeiten sowie Ermöglichung von Prozess-Automatisierungen</li>
                      <li>Zeitersparnis und Erhöhung der Qualität von Einweisungen</li>
                     <li>Erhöhte Transparenz für beide Seiten, Niedergelassene und Klinik</li>
                     <li>Erhöhung der Attraktivität für Einweisungen in Ihr Haus</li>
                     <li>Höherer Grad an Zufriedenheit bei den Einweisenden und Ihrer Belegschaft</li>
                    </ul>

                    
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/Patientenuebersicht.png"
                        alt="Zuweiserportal | Beispielansicht Patientenübersicht"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                      <StaticImage
                        src="../assets/Patientenasnsicht-Termine.png"
                        alt="Zuweiserportal | Beispielansicht Termine"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                      <StaticImage
                        src="../assets/Patientenansicht-Behandlungen.png"
                        alt="Zuweiserportal | Beispielansicht Behandlungen"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />

                      <StaticImage
                        src="../assets/Patientenansicht-Dok-uebersicht.png"
                        alt="Zuweiserportal | Beispielansicht Dokumenten Übersicht"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />

                      <StaticImage
                        src="../assets/Patientenansicht-Dokumentansicht.png"
                        alt="Zuweiserportal | Beispielansicht Dokument"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <section id="mitarbeiterportal" className={borderSoftOrange}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Mitarbeiter&shy;portal</h1>
                    <p className="text-medium">
                      Mit uns begleiten Sie Ihr Personal Digital – Das Modul
                      <span className="italic"> Mitarbeiterportal</span> unterstützt die bi-direktionale Kommunikation mit 
                      Ihrem Personal und das Bereitstellen persönlicher, vertraulicher und allgemeiner Informationen.
                    </p>
                    <p className="text-medium">
                    Die Lösung steht sowohl über Mobile App als auch über den Browser zur Verfügung und lässt sich über <span className="italic">health-engine®</span> nahtlos in Ihre hausinternen Anwendungen integrieren.
                    </p>
                    </div>
                  
                    <div className={col12}>
                    <p>Das Modul <span className="italic"> Mitarbeiterportal</span> wird wahlweise als SaaS aus der Cloud oder als On-Premise Lösung angeboten.</p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>
                    Das Mitarbeiterportal ermöglicht folgende optionale Ansichten und Hauptfunktionalitäten
                    </h3>
                    <ul>
                      <li>Design im CI der Klinik</li>
                      <li>Persönliche Lohnabrechnung</li>
                      <li>Schichtplan</li>
                      <li>Urlaubsanträge und Ansicht genehmigter Urlaubstage</li>
                      <li>Allgemeine Informationen Ihrer Marketing-/Kommunikationsabteilung</li>
                      <li>Kanal für Nachrichten der Geschäftsleitung</li>
                      <li>Speiseplan der Kantine </li>
                      <li>Möglichkeit der Einbindung von HTML5-fähigen Anwendungen</li>
                      <li>Management des Profils durch den Benutzer, inklusive Hinterlegung von administrativen Informationen </li>
                     
                    </ul>
                  </div>

                  <div className={col12}>
                  <h3>
                      Kommunikation über einen gesicherten Kanal
                    </h3>
                    <p>
                    Informationen werden den Mitarbeitenden über den digitalen Kanal unkompliziert und abgesichert zugestellt. Die Daten werden verschlüsselt übermittelt. 
                    </p>

                    <p>
                    Der Einsatz von moderner Front-End Technologie ermöglicht die Integration von Klinik-eigenen Anwendungen genauso wie eine einfache Präsentation auf verschiedensten Ausgabegeräten.
                    </p>
                    </div>

                  <div className={col12}>
                    <h3>Ihr Nutzen</h3>
                    <ul>
                      <li>Digitaler Zugang für Ihr Personal zu allgemeinen, persönlichen und vertraulichen Dokumenten</li>
                      <li>Zeitersparnis durch Transparenz</li>
                      <li>Möglichkeit der Automatisierung von Prozessen (z.B. Urlaubsantrag)</li>
                     <li>Dokumentation von Aktionen / Anfragen / Beschlüssen etc.</li>
                     <li>Erhöhung der Attraktivität des Arbeitsplatzes für Mitarbeitende</li>
                     <li>Höherer Grad an Zufriedenheit in der Belegschaft</li>
                    </ul>

                    
                  </div>
                </div>

            
              </div>
            </section>

            */}
            <section id="sprechstunde" className={borderPurple}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>SprechstundePlus</h1>
                    <p className="text-medium">
                    Ein Anwendungsfall des Moduls <span className="italic"> SprechstundePlus </span> ist die präoperative Abklärungen und dient der digitalen Vorbereitung der Anästhesie-Sprechstunde. Alle 
                    relevanten Informationen werden zentral in einer Anästhesieakte gesammelt und stehen den Anästhesisten übersichtlich zur Verfügung.
                    </p>
                    </div>

                    <div className={col12}>
                    <p>Zum Video der Hirslanden-Gruppe: <span className="italic-blue">Digitalisierung im Bereich der Anästhesie-Sprechstunde</span></p>
                   <div className={buttonGroup}><a href="https://www.youtube.com/watch?v=ViBkuFZ92dM"className="button-outline orange" target="_blank" rel="noreferrer"> Zum YouTube Video der Hirslanden-Gruppe </a> </div>
                   
                  </div>
                

               
                  <div className={col12}>
                    <h3>
                    Das Problem
                    </h3>
                    <ul>
                    <li>Unklarer Informationstransfer zwischen Haus- / Belegarzt und Anästhesie-Sprechstunden-Team</li>
                    <li>Viel administrativer Aufwand für Anästhesie-Sprechstunden-Team beim Gesundheitsfragebogen, den Abklärungen und der Terminfindung</li>
                    <li>Auslastungslücken</li>
                    <li>Patientenunfreundlich</li>
                     
                    </ul>
                  </div>

                  <div className={col12}>
                  <h3>Die Lösung</h3>
                    <ul>
                      <li>Anästhesie-Dossier für kompakte Zusammenstellung aller relevanten Informationen für die Anästhesiesprechstunde</li>
                      <li>Automatisierter Versand von Link auf Formular von Gesundheitsfragebogen per E-Mail = strukturierte Daten</li>
                      <li>Online Terminbuchung für Sprechstunde</li>
                      </ul>
                    </div>

                  <div className={col12}>
                    <h3>Ihr Nutzen</h3>
                    <ul>
                      <li>Schnellere Vorbereitung der Anästhesie Sprechstunde</li>
                      <li>Automatisierung durch strukturierte Informationen</li>
                      <li>Reduzierung von administrativem Aufwand bei Terminbuchungen</li>
                      <li>Mehr Gewinn durch bessere Auslastung der OP-Säle</li>
                    
                    </ul>

                    
                  </div>
                </div>
                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/praeop.png"
                        alt="Patientenportal | ANA-Cockpit Fragenbogen"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                        
                    </Slider>
                  </div>
                </div>
            
              </div>
            </section>



            <section id="telematikinfrastruktur" className={borderGreen}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>Telema&shy;tik&shy;infra&shy;struk&shy;tur, ePA, KIM</h1>
                    <p className="text-medium">
                      <span className="italic">health-engine®</span> als Informationslogistikplattform ist das
                      richtige Werkzeug für die Anbindung Ihres Krankenhauses an
                      die Telematikinfrastruktur.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <p>
                      Als Clinical Data Repository mit Schnittstellen zu allen
                      Umsystemen in ihrem Krankenhaus eignet sich <span className="italic">health-engine®</span>
                      bestens als zentraler Knotenpunkt für die Anbindung ihrer
                      Systeme an die Telematikinfrastruktur und erspart Ihnen
                      das Anbinden einzelner Systeme. <span className="italic">health-engine®</span> hat ihre
                      Konformitätsbestätigung der gematik erhalten für
                    </p>
                  </div>
                  <div className={col12}>
                    <p>
                      ePA – Elektronische Patientenakte
                      <br />
                      KIM – Kommunikation im Medizinwesen
                      <br />
                      E-Rezept – Elektronisches Rezept
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <Slider>
                      <StaticImage
                        src="../assets/telemetikinfrastruktur01.png"
                        alt="die Metadaten Reise"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                      <StaticImage
                        src="../assets/telemetikinfrastruktur02.png"
                        alt="Beispielansicht ePA Viewer"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                      <StaticImage
                        src="../assets/telemetikinfrastruktur03.png"
                        alt="Selektiver Ladevorgang der Dokumente direkt in die verknüpfte Patientenakte - Zuordnung zum Fall möglich"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                      <StaticImage
                        src="../assets/telemetikinfrastruktur04.png"
                        alt="Beispielansicht KIM-Mailversand"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </section>


            <section id="art" className={borderBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ART – Anfrage und Rückweisungs Tool</h1>
                    <p className="text-medium">
                      ART ist die hilfreiche Softwarelösung zur Bearbeitung von
                      Anfragen und Rückweisungen der Kostenträger im Alltag des
                      Gesundheitswesens. Es hat vorteilhafte Eigenschaften und
                      kann Sie bei der Arbeit effizient und professionell
                      unterstützen.{" "}
                    </p>
                    <p className="text-medium">
                      {" "}
                      Das Modul befindet sich bereits seit mehreren Jahren im
                      Einsatz bei zahleichen Spitälern in der Schweiz und hat
                      wesentlich zu einer messbaren Verbesserung des
                      wirtschaftlichen Arbeitens in den Spitälern beigetragen.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Überblick</h3>
                    <ul>
                      <li>
                        Erfassung aller Anfragen und Rückweisungen: Brief, Fax,
                        Telefon, E-Mail, XML 4.4
                      </li>
                      <li>
                        Überblick über alle Anfragen, Summen und Gesamtsummen
                      </li>
                      <li>
                        Schnelle Bearbeitung und Beantwortung dank
                        Prozesssteuerung
                      </li>
                      <li>Zuweisung an die Bearbeiter und </li>
                      <li>Terminkontrolle</li>
                      <li>
                        Direkter Zugriff auf alle administrativen und
                        medizinischen Informationen
                      </li>
                      <li>
                        Automatische Antwort an die Garanten mit allen nötigen
                        Unterlagen
                      </li>
                      <li>
                        Rascher Abbau der offenen Zahlungen Auswertungen
                        grafisch und tabellarisch
                      </li>
                    </ul>

                    <h3 className={marginTopSubstitute}>Constraints</h3>
                    <p>
                      Unter Constraints versteht man Ordner, in welchen Objekte
                      gesammelt werden, die bestimmte Bedingungen erfüllen. Für
                      das Modul ART wurden folgende Constraints erstellt:
                    </p>
                    <ul>
                      <li>Triage</li>
                      <li>Zurück in Triage</li>
                      <li>Zuweisung</li>
                      <li>Bearbeitung</li>
                      <li>Interne Unteraufträge</li>
                      <li>Externe Unteraufträge</li>
                      <li>In Arbeit</li>
                    </ul>

                    <h3 className={marginTopSubstitute}>ART-Dossier</h3>
                    <p>
                      Das ART-Dossier ist das Kernstück dieses Moduls. Es kann
                      auf vier verschiedenen Wegen initiiert werden:
                    </p>
                    <ul>
                      <li>
                        <span className="blue">Scan:</span> Eine Rückweisung
                        wird eingescannt und ein dazugehöriges ART-Dossier wird
                        erzeugt
                      </li>
                      <li>
                        XML4: Rückweisungen können auch via XML4- Schnittstelle
                        eingehen
                      </li>
                      <li>
                        Manuelle Erfassung: Es können auch ART- Dossier per
                        Webformular erstellt werden. Dabei gibt es zwei Wege:
                        <ul>
                          <li>
                            Entweder ein Webformular erstellt ein ART-Dossier,
                            welches in der Triage landet oder ein Webformular
                            erstellt
                          </li>
                          <li>
                            Ein ART-Dossier, welches direkt einem Benutzer des
                            eigenen Segmentes zugewiesen werden kann
                          </li>
                        </ul>
                      </li>
                      <li>
                        E-Mail: Als vierte Eingangsart gibt es E- Mails. Diese
                        können per Drag’n’Drop in einen Ordner gezogen werden
                        oder an eine spezifizierte E-Mail-Adresse gesendet wer-
                        den. *noch nicht im Betrieb, jedoch in der Applikation
                        bereits umgesetzt
                      </li>
                    </ul>
                  </div>

                  <div className={col12}>
                    <h3>Volltextsucher</h3>
                    <p>
                      Der Volltextsucher erlaubt es Metadaten und Dokumente
                      aller im ART abgelegten Objekte zu durchsuchen. Zum
                      Beispiel:
                    </p>
                    <ul>
                      <li>ART-Dossier</li>
                      <li>Dokumente</li>
                      <li>Interner Unterauftrag</li>
                      <li>Externer Unterauftrag</li>
                      <li>Noitz</li>
                    </ul>

                    <h3 className={marginTopSubstitute}>Reports</h3>
                    <ul>
                      <li>Abschlüsse</li>
                      <li>Aktive Benutzer</li>
                      <li>Anzahl Dossiers</li>
                      <li>Alter der Dossiers, noch nie in Bearbeitung</li>
                      <li>Anzahl Dossiers aktuell in Bearbeitung</li>
                      <li>Abgelaufene Fristen von Unteraufträgen</li>
                      <li>Anzahl Dossiers von Benutzer übernommen</li>
                      <li>Anzahl Abschlüsse pro Benutze</li>
                      <li>
                        Anzahl Dossiers, von welchem Segment zurückgewiesen
                      </li>
                      <li>Anzahl neue Dossiers in Zuweisung überführt</li>
                      <li>Anzahl neue Dossiers, noch nicht zugewiesen</li>
                      <li>
                        Dossiers nach Themen– und Anfragecodes, Erledigungscodes
                      </li>
                    </ul>

                    <div className={col12}>
                      <div className={marginTopSubstitute}>
                        <Slider>
                          <StaticImage
                            src="../assets/art01.svg"
                            alt="Reports"
                            placeholder="none"
                            layout="fullWidth"
                            formats={["auto", "webp", "avif"]}
                          />
                          <StaticImage
                            src="../assets/art02.svg"
                            alt="Beispielansicht ART-Dossier Workflow"
                            placeholder="none"
                            layout="fullWidth"
                            formats={["auto", "webp", "avif"]}
                          />
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={row}></div>
              </div>
            </section>

            <section id="md-akte" className={borderSpringGreen}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>MD Akte</h1>
                    <p className="text-medium">
                    <span className="italic">health-engine®</span> hat mit der konsolidierten Patientenakte
                      eine ideale Ausgangslage für das Aufbereiten der MD
                      relevanten Dokumente.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <p>
                      Im Rahmen der deutschlandweiten
                      „elektronischen-Vorgangsübermittlungs-Vereinbarung“ (eVV)
                      müssen Dokumente zwischen Krankenhäusern und den
                      Medizinischen Diensten (MD) von nun an ausschliesslich
                      elektronisch übermittelt werden.
                    </p>

                    <p>
                    <span className="italic">health-engine®</span> bietet hierfür als Erweiterung der
                      Patientenakte das ideale Produkt - die MD Akte.
                    </p>

                    <p>
                      Aus der konsolidierten Patientenakte können die
                      medizinischen Controllingabteilungen alle benötigten
                      Dokumente in der MD Akte aufbereiten und somit eine
                      präzise Behandlung der Prüfanzeige erzielen.
                    </p>

                    <p>
                      Mit der Schnittstelle zum Leistungserbringerportal (LE
                      Portal) werden die Dokumente des MD automatisch
                      heruntergeladen und bei Abschluss des Prüfvorgangs bequem
                      aus der MD Akte ins Portal hochgeladen.
                    </p>

                    <p>
                      Die MD Akte selbst wird durch die Schnittstelle angelegt
                      und durchläuft einen Prozess, der krankenhausspezifisch
                      konfiguriert werden kann. Damit hat man volle Kontrolle
                      über Fristeinhaltung, Zuweisungen von Aufgaben,
                      Berechtigungen sowie vollständige Protokollierung aller
                      Aktivitäten der MD Akte und deren Dokumente.
                    </p>

                    <p>Das MD Akten Modul in einem Video erklärt.</p>
                   <span style={{ marginTop: 0 }} className={buttonGroup}><a href="https://www.tie.ch/landingpages/md-akte"className="button-outline orange" target="_blank" rel="noreferrer"> Zum Video </a> </span>
                  </div>

                  <div className={col12}>
                    <h3>Was ist ihr Mehrwert</h3>
                    <ul>
                      <li>Automatisiertes Workflow Management</li>
                      <li>Effizientere Prozesse durch Prozess-Steuerung</li>
                      <li>
                        Senkung der Prüfquote durch Kategorisierung und
                        Auswertungen der Prüfanfragen
                      </li>
                      <li>Status der Prüfanzeige jederzeit einsehbar</li>
                      <li>Kontrolle über Fristeinhaltung</li>
                      <li>
                        Schnellere Rückzahlungen und transparente
                        Kostenkontrolle
                      </li>
                      <li>
                        Kürzere Kommunikationswege (ausschliesslich
                        elektronisch)
                      </li>
                      <li>Kleinerer Personalaufwand</li>
                      <li>eVV Konformität</li>
                    </ul>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <div style={{ maxWidth: 700 }}>
                      <Slider>
                        <StaticImage
                          src="../assets/mdakte01.svg"
                          alt="Schemantischer Aufbau der MD Akte mit Anbindung ans LE Portal"
                          placeholder="none"
                          layout="fullWidth"
                          formats={["auto", "webp", "avif"]}
                        />
                        <StaticImage
                          src="../assets/mdakte02.svg"
                          alt="Lösungsansatz MD Modul - Prozesse"
                          placeholder="none"
                          layout="fullWidth"
                          formats={["auto", "webp", "avif"]}
                        />
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <button
        className={`${moduleNavButton} ${
          mobileModuleNavIsVisible ? moduleNavButtonOpen : ""
        }`}
        onClick={handleMobileModuleNavClick}
      >
        <span className={barTop}></span>
        <span className={barMid}></span>
        <span className={barBot}></span>
      </button>
    </Layout>
  );
};

export default ModulePage;
